<script>
/* eslint-disable */
import Chart from 'chart.js'
import { Line, mixins, generateChart } from 'vue-chartjs'
import helper from '@/utils/helper'
import _ from 'lodash'
import constants from '@/constants/constants'

Chart.defaults.LineWithLine = Chart.defaults.line
Chart.defaults.global.hover.intersect = false
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function (ease) {
    Chart.controllers.line.prototype.draw.call(this, ease)

    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      const activePoint = this.chart.tooltip._active[0]
      const ctx = this.chart.ctx
      const x = activePoint.tooltipPosition().x
      const topY = this.chart.legend.bottom
      const bottomY = this.chart.chartArea.bottom

      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, topY)
      ctx.lineTo(x, bottomY)
      ctx.lineWidth = 1
      ctx.strokeStyle = constants.gridLinesColors.grey,
      ctx.stroke()
      ctx.restore()
    }
  },
})



Chart.Tooltip.positioners.custom = (elements, eventPosition) => {
  let x = eventPosition.x
  let y = eventPosition.y
  let top_element = elements[0]

  for (var i = 0,len = elements.length; i < len; ++i) {
      if (elements[i].tooltipPosition().y < top_element.tooltipPosition().y) {
        top_element = elements[i]
      }
  }

  const tp = top_element.tooltipPosition()

  x = tp.x
  y = tp.y

  return { x: x, y: y }
}

Chart.defaults.global.legend.onClick = function(e, legendItem) {
  const idx = legendItem.datasetIndex
  this.chart.options.scales.yAxes[idx].display = !this.chart.options.scales.yAxes[idx].display;

  const meta = this.chart.getDatasetMeta(idx);
  meta.hidden = meta.hidden === null ? !this.chart.data.datasets[idx].hidden : null;

  this.chart.update();
};

const BCMChart = generateChart('bitcoinmetrics-chart', 'LineWithLine')

export default {
  name:'DappChart',
  extends: BCMChart,
  mixins: mixins.reactiveProp,
  props: {
    'params': { type: Object,default: () => {} },
    'chartData': { type: Array, default: () => [] },
    'isMultiaxis': { type: Boolean, default: () => false }
  },
  data: () => ({
    helper: helper,
    chart: null,
    dataSets: [],
    colors: [
      constants.chartAxisColors.primary,
      constants.chartAxisColors.secondary_3,
      constants.chartAxisColors.secondary_1,
      constants.chartAxisColors.red,
      constants.chartAxisColors.darkyellow,
      constants.chartAxisColors.cyan,
      constants.chartAxisColors.lightgrey,
    ],
    yAxes: [],
    xAxes: [],
    chart: null,
    hasImage: false,
  }),
  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        layout: {
          padding: 0
        },
        animation: {
          duration: 0.5,
        },
        scales: {
          yAxes: this.yAxes,
          xAxes: this.xAxes,
        },
        tooltips: {
          mode: 'x-axis',
          enabled: true,
          position: 'custom',
          titleAlign: 'right',
          bodyAlign: 'right',
          titleFontStyle: 'normal',
          titleFontSize: 13,
          displayColors: true,
          bodyFontSize: 13,
          caretSize: 0,
          custom: (tooltip, position) => {
            tooltip.displayColors = true
          },
          callbacks: {
            title: (i, datasets) => {
              return new Date(i[0].label).toLocaleString()
            },
            label: (i, datasets) => {
              const multiLine = []
              const tempLabel = `  ${ helper.roundDecimalPlaces(parseFloat(i.value)) }`

              multiLine.push(this.dataSets[i.datasetIndex]?.tooltipTitle)
              multiLine.push(tempLabel)

              return multiLine
            },
            afterLabel: function(i, data) {
              return i.datasetIndex < data.datasets.length - 1 ? ' ' : ''
            },
          }
        },
        legend: {
          display: false,
          legendCallback: (chart) => {
            var text = [];
            text.push('<ul class="' + chart.id + '-legend">');
            for (var i = 0; i < chart.data.datasets[0].data.length; i++) {
              text.push('<li><span style="background-color:' +
              chart.data.datasets[0].backgroundColor[i] + '">');
                if (chart.data.labels[i]) {
                text.push(chart.data.labels[i]);
              }
              text.push('</span></li>');
            }
            text.push('</ul>');
            return text.join("");
          },
        },
      }
    },
  },
  watch: {
     'chartData': {
      handler: function (value) {
        this.createChart()
      },
      deep: true,
    },
    '$vuetify.theme.dark': {
      handler: function (value) {
        this.$vuetify.theme.dark = value
        this.createChart()
      },
      deep: true,
    },
    'isMultiaxis': {
      handler: function (value) {
        this.createChart()
      },
      deep: true,
    }
  },
  mounted() {
    this.createChart()
  },
  methods: {
    createChart() {
      const chartParam = this.chartData

      if (chartParam.length < 1) return
      this.dataSets = []
      let optDataSet = {
        borderWidth: 1,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 2,
        pointHoverRadius: 2,
        pointHoverBackgroundColor: 'transparent',
      }

      let canRender = true
      this.yAxes = []
      this.xAxes = []

      for (const [i, asset] of chartParam.entries()) {
        this.xAxes.push({
              id: 'xAxis',
              ticks: {
                fontSize: 10,
                fontColor: this.isMultiaxis ? chartParam[0].color : asset.color,
              },
              afterTickToLabelConversion: function (data) {
                const xLabels = data.ticks
                let tempLabel = ''

                xLabels.forEach((labels, i) => {
                  const xlabel = helper.datetimeWithMonth(xLabels[i])
                  if (tempLabel !==  xlabel) {
                    xLabels[i] = xlabel
                  } else {
                    xLabels[i] = ''
                  }
                  tempLabel = helper.datetimeWithMonth(labels)
                })
              },
              gridLines: {
                color: constants.gridLinesColors.grey,
                lineWidth: 1,
                zeroLineColor: constants.gridLinesColors.grey,
              },
        })

        this.yAxes.push({
          id: asset?.id,
          type: 'linear',
          position: i === 0 ? 'left' : 'right',
          ticks: {
            beginAtZero: false,
            fontSize: 10,
            fontColor: asset?.color ? asset?.color : this.colors[i],
            callback: (value, index, values) => {
              const price = value > 999 ? helper.abbreviateNumber(value) : helper.roundDecimalPlaces(value)
                  return `${
                    this.params.currency ? '$' : ''
                  }${price}`
            }
          },
          gridLines: {
              color: constants.gridLinesColors.grey,
              lineWidth: 1,
              zeroLineColor: constants.gridLinesColors.grey,
            }
        })

        this.dataSets.push({
          label: asset?.labels,
          data: asset?.data,
          yAxisID: asset?.id,
          backgroundColor: 'transparent',
          pointColor: asset?.color ? asset?.color : this.colors[i],
          borderColor: asset?.color ? asset?.color : this.colors[i],
          tooltipTitle: asset.tootipTitle,
          ...optDataSet
        })
      }

      if (!this.hasImage) this.addCustomPlugin()

      this.renderChart(
        {
          labels: chartParam[0]?.labels,
          datasets: this.dataSets,
        },
        this.options,
      )
    },
    addCustomPlugin() {
       this.addPlugin({
        afterDraw: (chart) => {
          const ctx = chart.ctx
          const image = new Image()
          image.src = require('@/assets/images/bcm-chart-small.png')
          image.height = 70
          image.width = 300

          ctx.drawImage(
            image,
            (chart.chart.width / 2) - 300 / 2, // x asix
            (chart.chart.height / 2) - 70 / 2 //y axis
          )

          ctx.save()
          ctx.restore()
          this.hasImage = true
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 47px;
  font-weight: bold;
  opacity: 0.2;
  z-index: -1;
  margin-top: 20px;
  position: absolute;
}

canvas {
  background-image: url("~@/assets/images/bcm-chart-small.png");
  background-position: center center;
  background-size: 300px 70px;
}
</style>
