<template>
  <v-container fluid>
    <Loader v-if="loading" class="pt-16 pb-10"/>
    <v-row v-else dense class="mb-4 mx-1">
      <v-col cols="12">
        <v-row dense>
          <v-col>
            <h1 class="toolbar--text">
              {{ `${$t("Dapp")}  ${$t("Activity")} ${$t("Details")} ` }}
            </h1>
          </v-col>
        </v-row>
        <v-divider class="my-3"/>
      </v-col>
      <v-col cols="12">
        <v-card class="card_bg">
          <v-row dense>
            <v-col cols="12">
              <v-row dense align="center" class="pa-4">
                <v-col cols="12" sm="4">
                  <p class="mb-0 body-2">
                    <span class="grey--text">{{ `${$t("Token")} ${$t("Address")}:` }}</span>
                  </p>
                  <p class="mb-0 body-2 ellipsis">
                    <span class="secondary--text">{{ $route.params.token }}</span>
                  </p>
                </v-col>
                <v-col cols="12" sm="2">
                  <p class="mb-0 body-2 ">
                    <span class="grey--text">{{ $t("Chain") }}:</span>
                  </p>
                  <p class="mb-0 body-2 ">
                    <span class="secondary--text">{{ $route.params.name }}</span>
                  </p>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-btn-toggle
                    class="float-left toggle-controls v-btn-group v-btn-group--3"
                    dense
                    mandatory
                  >
                    <v-btn
                      v-for="(btn, idx) in chartGroupType"
                      :key="idx"
                      class="body-2 uncapitalize"
                      @click="onChangeChartType(idx)"
                    >
                      <span>{{ btn.name }}</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" sm="3">

                  <v-btn-toggle
                    class="float-right v-btn-group v-btn-group--4 toggle-controls d-none d-sm-flex"
                    dense
                    mandatory
                  >
                    <v-btn
                      v-for="btn in daysGroup"
                      :key="btn.name"
                      :input-value="btn.selected"
                      class="body-2 uncapitalize px-2"
                      @click="onChangeDays(btn)"
                    >
                      <span>{{ btn.name }}</span>
                    </v-btn>
                  </v-btn-toggle>

                  <Menu
                    :button-size="`primary no-hover-bg uncapitalize d-flex d-sm-none`"
                    :selected-text="selected_days.name"
                    :list-items="daysGroup"
                    key-item="name"
                    @click="onChangeDaysMenu($event)"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <Loader v-if="chart_loading" class="pt-16 pb-10" :height="'362px'" />
                  <template v-else>
                    <div v-if="isCustomLegendsVisible && chartType < 1" class="d-flex f-16 flex-wrap justify-center">
                      <v-checkbox
                        v-for="(legend, index) in legends"
                        :key="index"
                        v-model="selected_legends"
                        class="mx-2"
                        :color="legend.color"
                        multiple
                        :hide-details="index !== legends.length - 1"
                        :value="legend.name"
                        @change="onLegendChange($event, index)"
                      >
                        <template v-slot:label>
                          <div
                            class="custom-legend f-14"
                            :style="{ background: legend.color }"
                          >
                          </div>
                          <span class="mx-1 f-14">{{ legend.name }}</span>
                        </template>
                      </v-checkbox>
                    </div>
                    <SimpleChart v-if="chartType < 1" :params="params" :is-multiaxis="true" :chart-data="simpleDataset" />
                    <template v-else>
                      <v-row>
                        <v-col cols="12" lg="6">
                          <SimpleChart :params="params" :chart-data="[getTransactionCount()].flat()" />
                        </v-col>
                        <v-col cols="12" lg="6">
                          <SimpleChart :params="params" :chart-data="[getValue()].flat()" />
                        </v-col>
                        <v-col cols="12" lg="6">
                          <SimpleChart :params="params" :chart-data="[getSentCount()].flat()" />
                        </v-col>
                      </v-row>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SimpleChart from '@/components/organisms/chart/DappChart'
import Loader from '@/components/molecules/Loader'
import Menu from '@/components/atoms/Menu'
import constants from '@/constants/constants'
import { get, dispatch, commit, call } from 'vuex-pathify'
import helper from '@/utils/helper'
import common from '@/shared/common'

export default {
  name:'DappActivityDetails',
  components: {
    SimpleChart,
    Loader,
    Menu,
  },
  data: () => ({
    chartType: 0,
    helper: helper,
    chart_loading: false,
    labels: [],
    chartParam: [],
    simpleDataset: [],
    selected_days: { name: '7D', selected: true },
    common: common,
    daysGroup: common.daysGroup.filter((x) => x.name !== '1D'),
    chartGroupType: [
      { name: 'Multi-Axis', id: 1,  selected: true },
      { name: 'Multi-Chart', id: 2, selected: false },
    ],
    days: '7D',
    params: {},
    selected_legends: ['Transaction Count'],
    isCustomLegendsVisible: true,
    legends: [
      { name: 'Transaction Count', color: constants.chartAxisColors.primary },
      { name: 'Value', color: constants.chartAxisColors.secondary_3 },
      { name: 'Sent Count', color: constants.chartAxisColors.secondary_1 },
    ],
  }),
  computed: {
    loading: get('loading'),
    userCountChartData: get('overview/userCountChartData'),
    transValueChartData: get('overview/transValueChartData'),
    chartData: get('overview/chartData'),
  },
  async mounted() {
    this.$route.params.id =
        typeof this.$route.params.name === 'string'
          ? parseFloat(this.$route.params.name)
          : this.$route.params.id

    commit('SHOW_LOADER', true)
    await this.onFetchActivity()
    commit('SHOW_LOADER', false)
  },
  methods: {
    ...call('overview', ['DAPP_ACTIVITY_DETAILS_REQUEST']),
    async onChangeDaysMenu(item) {
      this.common.daysGroup.forEach((o) => {
        this.days =  o.name.toLowerCase() === item.name.toLowerCase() ? o.name : this.days
        o.selected = o.name === item.name ? true : false
      })

      this.selected_days = item
      this.days = item.name
      await this.onFetchActivity()
    },
    async onChangeDays(val) {
      this.days = val.name
      this.common.daysGroup.forEach((o) => {
        o.selected = o.name === val.name ? true : false
      })

      this.selected_days = val
      this.days = val.name
      await this.onFetchActivity()
      this.changeChartData()
    },
    onChangeChartType(type) {
      this.chartType = type
      this.changeChartData()
    },
    changeChartData() {
      this.simpleDataset = []
      this.chartParam = this.$store.getters['overview/getChartData']
      this.simpleDataset.push(this.getTransactionCount())
    },
    getTransactionCount() {
      return {
        tootipTitle: 'Transaction Count',
        name: this.chartParam.name,
        labels: this.chartParam.label,
        data: this.chartData,
        id: 'bcm_transaction',
        color: constants.chartAxisColors.primary,
      }
    },
    getValue() {
      return {
        tootipTitle: 'Value',
        name: this.chartParam.name,
        labels: this.chartParam.label,
        data: this.transValueChartData,
        id: 'bcm_value',
        color: constants.chartAxisColors.secondary_3,
      }
    },
    getSentCount() {
      return {
        tootipTitle: 'Sent Count',
        name: this.chartParam.name,
        labels: this.chartParam.label,
        data: this.userCountChartData,
        id: 'bcm_sentcount',
        color: constants.chartAxisColors.secondary_1,
      }
    },
    async onFetchActivity() {
      this.chart_loading = true
      const payload = {
        token_address: this.$route.params.token,
        sort: 'asc',
        chain: this.$route.params.name,
        days: this.days,
      }

      await this.DAPP_ACTIVITY_DETAILS_REQUEST(payload)
      this.changeChartData()
      this.chart_loading = false
    },
    onLegendChange(value, index) {
      this.simpleDataset = []
      if (this.selected_legends && this.selected_legends.length > 0) {
        _.forEach(this.selected_legends, (o) => {
          if (o === 'Transaction Count') {
            this.simpleDataset.push(this.getTransactionCount())
          } else if (o === 'Value') {
            this.simpleDataset.push(this.getValue())
          } else if (o === 'Sent Count') {
            this.simpleDataset.push(this.getSentCount())
          } else {
            if (!(this.simpleDataset?.length > 0)) {
              this.simpleDataset.push(this.getTransactionCount())
              this.simpleDataset.push(this.getValue())
              this.simpleDataset.push(this.getSentCount())
            }
          }
        })
      } else {
        this.simpleDataset.push(this.getTransactionCount())
      }
    },
  },

}
</script>

<style lang="scss" scoped>
  .ellipsis {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
